import { ICard } from "../api/models/card";
import { GenderEnum } from "../api/models/user";
import { IUserAddress } from "../api/models/user_address";
import router from "../router";
import { useCartStore } from "../store/cart";
import { useProductStore } from "../store/product";
import { useUserStore } from "../store/user";

import { generalInfo } from "./constants";

export interface IIcon {
  name: string;
  source: "assets" | "material-symbols" | "line-awesome";
  classes?: string;
  extension?: "svg" | "png";
  size?: string;
  type?: "outlined" | "rounded" | "sharp" | "two-tone";
  filled?: boolean;
  isBrand?: boolean;
}

export function joinPaths(...paths: string[]) {
  return paths
    .map((path, index) =>
      index === 0 ? path.replace(/\/$/g, "") : path.replace(/\/$/g, "").replace(/^\//g, "")
    )
    .join("/");
}

export function selectAddress(
  id: string | null,
  addresses: IUserAddress[],
  { selectCheckoutAddress = false }: { selectCheckoutAddress?: boolean } = {}
) {
  addresses.forEach((address) => {
    if (address.id === id) {
      address.isSelected = true;
      if (selectCheckoutAddress) {
        const cartStore = useCartStore();
        cartStore.selectedCheckoutAddress = address;
      }
    } else {
      address.isSelected = false;
    }
  });
}

export function getSelectedAddress(addresses: IUserAddress[]) {
  return addresses.find(({ isSelected }) => isSelected);
}

export function selectCard(
  id: string | null,
  cards: ICard[],
  { selectedCheckoutCard = false }: { selectedCheckoutCard?: boolean } = {}
) {
  cards.forEach((card) => {
    if (card.id === id) {
      card.isSelected = true;
      if (selectedCheckoutCard) {
        const cartStore = useCartStore();
        cartStore.selectedCheckoutCard = card;
      }
    } else {
      card.isSelected = false;
    }
  });
}

export function getSelectedCard(cards: ICard[]) {
  return cards.find(({ isSelected }) => isSelected);
}

export async function redirectToWhatsApp() {
  const userStore = useUserStore();
  const route = router.currentRoute.value;
  const isOnProductPage = route.path.startsWith("/produto/");
  const phoneNumber = generalInfo.contactNumber;
  const message: string[] = ["Olá!"];

  if (userStore.activeUser) {
    message.push(`Meu nome é ${userStore.activeUser.name.split(" ")[0]}.`);
  }
  if (isOnProductPage) {
    message.push(
      `Estou interessad${userStore.activeUser?.gender === GenderEnum.FEMALE ? "a" : "o"}`
    );
    message.push(`no produto ${
      useProductStore().product!.name
    }. Poderia me fornecer mais informações? \n
    Aqui está o link: ${window.location.href}`);
  } else {
    message.push("Preciso de ajuda com algumas informações. Poderia me ajudar, por favor?");
  }

  const encodedMessage = encodeURIComponent(message.join(" "));

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  window.open(whatsappUrl, "_blank");
}
