import { defineStore } from "pinia";

const breakpoints = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 384,
};

const breakpointsSizes = Object.keys(breakpoints);
const breakpointsPixels = Object.values(breakpoints);

export type Breakpoint = keyof typeof breakpoints | undefined;

let isTracking = false;
let lastBreakpoint: Breakpoint;
const callbacks: ((breakpoint: Breakpoint) => any)[] = [];
const onBreakpoint = new CustomEvent("breakpoint");

export const useBreakpointStore = defineStore({
  id: "breakpoint",
  state: () => {
    return {
      screenWidth: 0,
      currentBreakpoint: undefined as Breakpoint,
    };
  },
  actions: {
    _setCurrentBreakpoint() {
      const width = window.innerWidth;
      this.screenWidth = width;

      const pointIndex = breakpointsPixels.findIndex((pixels) => width >= pixels);
      this.currentBreakpoint = <Breakpoint>breakpointsSizes[pointIndex];
    },
    startScreenSizeListener() {
      if (isTracking) {
        return;
      }

      window.addEventListener("breakpoint", () => {
        callbacks.forEach((callback) => callback(this.currentBreakpoint));
      });

      this._setCurrentBreakpoint();
      lastBreakpoint = this.currentBreakpoint;

      window.addEventListener("resize", () => {
        this._setCurrentBreakpoint();

        if (this.currentBreakpoint !== lastBreakpoint) {
          window.dispatchEvent(onBreakpoint);
        }

        lastBreakpoint = this.currentBreakpoint;
      });

      isTracking = true;
    },
    captureBreakpoint(callback: (breakpoint: Breakpoint) => any) {
      callback(this.currentBreakpoint);
      if (!callbacks.includes(callback)) {
        callbacks.push(callback);
      }
    },
  },
});
