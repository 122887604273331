import { apiGet } from "./_server";
import { IGetProductsListDto, IProduct, IProductPaginationResponseDto } from "./models/product";

export async function getProducts(
  query: IGetProductsListDto
): Promise<IProductPaginationResponseDto> {
  return apiGet({ path: "app/product", config: { params: { json: JSON.stringify(query) } } });
}

export async function getProductBySlug(slug: string): Promise<IProduct> {
  return apiGet({ path: `app/product/${slug}` });
}
