import { defineStore } from "pinia";

import { login, authenticateWithGoogle, logout, signUp } from "../api/auth";
import { ISession, ISignUpRequest } from "../api/models/auth";
import { UserRoleEnum } from "../api/models/user";
import router from "../router";
import { fetchAndStoreCart, syncLocalCartWithRemote, toast } from "../utils";

import { useUserStore } from "./user";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => {
    return {
      accessToken:
        localStorage.getItem("accessToken") ??
        (sessionStorage.getItem("accessToken") as string | null),
    };
  },
  getters: {
    isUserLoggedIn(state) {
      return !!state.accessToken;
    },
  },
  actions: {
    setToken({ token, rememberMe }: { token: string | null; rememberMe?: boolean }) {
      if (token === null) {
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("accessToken");
        this.$reset();

        return;
      }

      if (rememberMe) {
        localStorage.setItem("accessToken", token);
      } else {
        sessionStorage.setItem("accessToken", token);
      }

      this.$reset();
    },

    async login({
      identifier,
      password,
      rememberMe,
    }: {
      identifier: string;
      password: string;
      rememberMe?: boolean;
    }) {
      const session = await login({ identifier, password });
      this.setLoggedState({ session, rememberMe });
    },

    async signup(payload: ISignUpRequest) {
      const session = await signUp(payload);
      this.setLoggedState({ session, rememberMe: false });
    },

    /**
     * Autentica o usuário que fez sign in com o Google e faz login
     * @param token Token utilizado para autenticar o usuário
     */
    async authenticateWithGoogle(token: string) {
      const session = await authenticateWithGoogle({ token });
      this.setLoggedState({ session, rememberMe: true });
    },

    async logout() {
      await logout();
      this.clearSession();
    },

    setLoggedState({ session, rememberMe }: { session: ISession; rememberMe?: boolean }) {
      if (
        (import.meta.env.VITE_PROJECT_TYPE === "admin" &&
          session.user.role !== UserRoleEnum.ADMINISTRATOR) ||
        (import.meta.env.VITE_PROJECT_TYPE === "user" &&
          session.user.role === UserRoleEnum.ADMINISTRATOR)
      ) {
        logout({ headers: { Authorization: `Bearer ${session.token}` } });
        toast("error", "Você não tem permissão para realizar esta ação.");
        throw Error("USER_ROLE_NOT_ALLOWED");
      }

      this.setToken({ token: session.token, rememberMe });
      const userStore = useUserStore();
      userStore.setActiveUser(session.user);
      userStore.loadInitialLoggedInRoute();

      syncLocalCartWithRemote();
    },

    clearSession() {
      this.setToken({ token: null });
      const userStore = useUserStore();
      userStore.$reset();
      userStore.fetchRequiredDataOnStoreStart();
      fetchAndStoreCart();

      router.push("/login");
    },
  },
});
