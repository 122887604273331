<template>
  <component
    :is="componentTag"
    :type="routerLink || commonHref ? undefined : type"
    :href="commonHref"
    :disabled="disabled || isLoading"
    :class="[
      'flex w-fit cursor-pointer items-center font-secondary text-base uppercase transition duration-300 ease-in-out disabled:!cursor-default',
      classes.variant[variant],
      `justify-${align}`,
      componentClassesObject,
    ]"
    v-bind="disabled ? { to: '' } : routerLink"
    @click="emit('click', $event)"
  >
    <div v-if="isLoading" :class="['flex items-center', innerClass]">
      <LoadingSpinner
        :color="loadingColor ?? classes.loadingColor[props.variant]"
        :size="loadingSize"
        :stroke="loadingStroke"
      />
      <span v-if="loadingText" :class="['ml-4', classes.variant[variant]]">{{ loadingText }}</span>
    </div>
    <div v-else :class="['flex items-center', innerClass]">
      <Icon
        v-if="icon && ['both', 'left'].includes(iconSide)"
        class="icon-on-left-side mr-2"
        v-bind="icon"
      />
      <slot />
      <Icon
        v-if="icon && ['both', 'right'].includes(iconSide)"
        class="icon-on-right-side ml-2"
        v-bind="icon"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { RouterLinkProps } from "vue-router";

import { IIcon } from "../utils";

import Icon from "./Icon.vue";
import LoadingSpinner, { ColorsName } from "./LoadingSpinner.vue";

export type ButtonVariant =
  | "primary"
  | "primary-alt"
  | "secondary"
  | "tertiary"
  | "outlined"
  | "destructive";
export type ButtonAlign = "start" | "center" | "end";

const props = withDefaults(
  defineProps<{
    type?: "submit" | "button" | "reset";
    variant?: ButtonVariant;
    align?: ButtonAlign;
    routerLink?: RouterLinkProps;
    commonHref?: string;
    disabled?: boolean;
    innerClass?: string;
    customHover?: string;
    isLoading?: boolean;
    loadingText?: string;
    loadingSize?: number;
    loadingStroke?: number;
    loadingColor?: ColorsName;
    iconSide?: "left" | "right" | "both";
    icon?: IIcon;
  }>(),
  {
    type: "button",
    variant: "primary",
    align: "center",
    iconSide: "left",
    customHover: undefined,
    icon: undefined,
    routerLink: undefined,
    commonHref: undefined,
    innerClass: undefined,
    disabled: false,
    isLoading: false,
    loadingSize: 20,
    loadingStroke: 3,
    loadingColor: undefined,
    loadingText: undefined,
  }
);

interface IClasses {
  disabledBase: string;
  variant: { [Key in ButtonVariant]: string };
  hover: { [Key in ButtonVariant]: string };
  disabled: { [Key in ButtonVariant]: string };
  loadingColor: { [Key in ButtonVariant]: ColorsName };
}

const classes: IClasses = {
  disabledBase: "!opacity-50",
  variant: {
    primary: "bg-primary font-bold text-black px-8 min-h-button",
    "primary-alt": "bg-primary font-bold text-black px-8 min-h-button",
    secondary: "text-primary",
    tertiary: "rounded-full border px-3 py-2 hover:text-primary",
    outlined: "bg-transparent font-bold !text-primary px-8 min-h-button border-2 border-secondary",
    destructive:
      "focus:bg-error-secondary bg-transparent font-bold text-error px-8 min-h-button border-2 border-secondary",
  },
  hover: {
    primary: `hover:bg-pure-black hover:text-white ${props.customHover ?? ""}`,
    "primary-alt": `hover:bg-stronger-primary ${props.customHover ?? ""}`,
    secondary: "",
    tertiary: "",
    outlined: `hover:bg-secondary hover:border-secondary ${props.customHover ?? ""}`,
    destructive: "hover:bg-error-weaker",
  },
  disabled: {
    primary: "",
    "primary-alt": "",
    secondary: "",
    outlined: "",
    tertiary: "",
    destructive: "opacity-50",
  },
  loadingColor: {
    primary: "pure-white",
    "primary-alt": "pure-white",
    secondary: "primary",
    outlined: "primary",
    tertiary: "primary",
    destructive: "error",
  },
};

const componentClassesObject = computed(() => ({
  [classes.disabledBase]: props.disabled || props.isLoading,
  [classes.disabled[props.variant]]: props.disabled || props.isLoading,
  [classes.hover[props.variant]]: !props.disabled && !props.isLoading,
}));

const componentTag = computed(() => {
  if (props.routerLink) {
    return "router-link";
  } else if (props.commonHref) {
    return "a";
  } else {
    return "button";
  }
});

const emit = defineEmits<{ (event: "click", e: MouseEvent): void }>();
</script>
