import { format, parse, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { isArray } from "lodash";

import { isObject } from "./object";

export function formatToDate(value: Date | string) {
  return format(new Date(value), "dd/MM/yyyy");
}

export function formatToDatetime(value: Date | string) {
  return format(new Date(value), "dd/MM/yyyy HH:mm");
}

export function convertDateStringToISOInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string) => {
      if (value === "") {
        return null;
      } else {
        return parse(value, "dd/MM/yyyy", new Date());
      }
    },
    object,
    fields
  );
}

export function convertISOToDateInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value !== null) {
        return parseISO(value);
      }
    },
    object,
    fields
  );
}

export function convertISOToDateStringInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      return value ? format(parseISO(value), "dd/MM/yyyy") : null;
    },
    object,
    fields
  );
}

function executeFunctionOnAllObjectLayers(
  callback: (value: any) => any,
  object: Record<string, any>,
  fields: string[]
) {
  if (!object) {
    return;
  }

  Object.entries(object).forEach(([key, value]) => {
    if (value !== null && (isArray(value) || isObject(value))) {
      if (isArray(value)) {
        value.forEach((element) => {
          if (typeof element === "object") {
            executeFunctionOnAllObjectLayers(callback, element, fields);
          }
        });
      } else {
        executeFunctionOnAllObjectLayers(callback, value, fields);
      }
    } else {
      if (fields.includes(key)) {
        object[key] = callback(value);
      }
    }
  });

  return object;
}

export function formatToLongDate(date: Date) {
  return format(date, "dd' de 'MMMM' de 'u", { locale: ptBR });
}

export function toLocalDate(date: Date | string) {
  const datePart = typeof date === "string" ? date.split("T")[0] : date.toISOString().split("T")[0];

  return new Date(`${datePart}T00:00:00`);
}
