<template>
  <div class="bg-background-primary">
    <div v-if="userStore.isLoading" class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner color="primary" />
    </div>

    <NetworkError v-else-if="userStore.hasNetworkError" class="h-screen w-screen" />

    <div v-else>
      <router-view />
      <WhatsAppButton v-if="projectType === 'user'" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, watch } from "vue";

import LoadingSpinner from "./components/LoadingSpinner.vue";
import NetworkError from "./components/NetworkError.vue";
import WhatsAppButton from "./components/WhatsAppButton.vue";
import { useTheme } from "./composables/useTheme";
import { useBreakpointStore } from "./store/breakpoint";
import { useCartStore } from "./store/cart";
import { useUserStore } from "./store/user";
import { fetchAndStoreCart, hideOrShowGlobalScroll } from "./utils";

const projectType = import.meta.env.VITE_PROJECT_TYPE;

const userStore = useUserStore();
const cartStore = useCartStore();

const { loadTheme } = useTheme();
const breakpointStore = useBreakpointStore();

breakpointStore.startScreenSizeListener();

// Utilizar endpoints do e-commerce no setup somente como user
if (projectType === "user") {
  userStore.fetchRequiredDataOnStoreStart();

  if (!cartStore.items) {
    fetchAndStoreCart();
  }
}

watch(
  [() => userStore.isMobileMenuOpen, () => cartStore.isMenuOpen],
  (values) => {
    const isAnyOpen = values.some((value) => !!value);
    hideOrShowGlobalScroll("mobile-and-bag", isAnyOpen);
  },
  { immediate: true }
);

onMounted(() => loadTheme());
</script>
