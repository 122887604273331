import SweetScroll from "sweet-scroll";

import { useUserStore } from "../store/user";

import { toast } from "./toast";

export function copyToClipboard(value: string, successText = "Copiado") {
  navigator.clipboard.writeText(value);
  toast("success", successText);
}

export function resetBlockersAndShowGlobalScroll() {
  useUserStore().windowScrollBlockers = new Set();
  showGlobalScroll();
}

export function hideGlobalScroll() {
  document.body.style.overflowY = "hidden";
}

export function showGlobalScroll() {
  document.body.style.overflowY = "auto";
}

export function hideOrShowGlobalScroll(id: string, isOpen: boolean) {
  const userStore = useUserStore();
  if (isOpen) {
    userStore.windowScrollBlockers.add(id);
  } else {
    userStore.windowScrollBlockers.delete(id);
  }

  if (userStore.windowScrollBlockers.size) {
    hideGlobalScroll();
  } else {
    showGlobalScroll();
  }
}

export function scrollToCenter(element: HTMLElement) {
  const scroller = new SweetScroll({ duration: 500 });

  // Obter a posição do elemento
  const elementRect = element.getBoundingClientRect();
  const elementVerticalPosition = elementRect.top + window.pageYOffset;

  // Calcular a posição para centralizar o elemento
  const offset = window.innerHeight / 2 - elementRect.height / 2;
  const scrollPosition = elementVerticalPosition - offset;

  // Scroll para a posição calculada
  scroller.to(scrollPosition);
}

export function scrollToFirstInvalidInputIfExists() {
  const firstInputError = [...$(".input-error")].find((element) => element.innerText);
  if (firstInputError) {
    const firstInvalidInput = firstInputError.parentElement!;
    scrollToCenter(firstInvalidInput); // Scroll para o primeiro input inválido
  }
}
